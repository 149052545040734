import { formatInt, getCookie } from '@/utils/common'

export class UDate {
  #Date = null

  constructor (...args) {
    const date = new Date(...args)

    if (date.toString() === 'Invalid Date') {
      this.#Date = null
      return
    }

    this.#Date = date.valueOf() > 0 ? date : null
  }

  valueOf () {
    if (!this.#Date) {
      return null
    }

    return this.#Date.valueOf()
  }

  static formatDate (...args) {
    const fmt = new Intl.DateTimeFormat(undefined, {
      dateStyle: 'short'
    })

    const d = new UDate(...args)
    return d.valueOf() ? fmt.format(d) : ''
  }

  static formatDateTime (...args) {
    const fmt = new Intl.DateTimeFormat(undefined, {
      dateStyle: 'short',
      timeStyle: 'short'
    })

    const d = new UDate(...args)
    return d.valueOf() ? fmt.format(d) : ''
  }

  static formatTime (seconds) {
    let units
    const lang = getCookie('lang') || 'ru'
    if (lang === 'en') {
      units = {
        hours: ['hour', 'hours', 'hours'],
        minutes: ['minute', 'minutes', 'minutes'],
        seconds: ['second', 'seconds', 'seconds']
      }
    } else {
      units = {
        hours: ['час', 'часа', 'часов'],
        minutes: ['минута', 'минуты', 'минут'],
        seconds: ['секунда', 'секунды', 'секунд']
      }
    }

    const hours = Math.floor(seconds / UDate.hourInSeconds)
    seconds -= hours * UDate.hourInSeconds

    const minutes = Math.floor(seconds / UDate.minuteInSeconds)
    seconds -= minutes * UDate.minuteInSeconds

    return [
      (hours > 0) ? formatInt(hours, units.hours) : '',
      (minutes > 0) ? formatInt(minutes, units.minutes) : '',
      (seconds > 0) ? formatInt(seconds, units.seconds) : ''
    ].join(' ').trim()
  }

  static get weekInMilliseconds () {
    return 604800000
  }

  static get dayInMilliseconds () {
    return 86400000
  }

  static get hourInMilliseconds () {
    return 3600000
  }

  static get minuteInMilliseconds () {
    return 60000
  }

  static get secondInMilliseconds () {
    return 1000
  }

  static get quarterInMilliseconds () {
    return 7884000000
  }

  static get monthInMilliseconds () {
    return 2629800000
  }

  static get hourInSeconds () {
    return UDate.hourInMilliseconds / UDate.secondInMilliseconds
  }

  static get minuteInSeconds () {
    return UDate.minuteInMilliseconds / UDate.secondInMilliseconds
  }
}
